import { render, staticRenderFns } from "./SaleResult.vue?vue&type=template&id=150b210e&scoped=true&"
import script from "./SaleResult.vue?vue&type=script&lang=js&"
export * from "./SaleResult.vue?vue&type=script&lang=js&"
import style0 from "./SaleResult.vue?vue&type=style&index=0&id=150b210e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "150b210e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VDataTable,VProgressLinear})
